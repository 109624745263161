.lp-modal-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}

.lp-modal-backdrop label {
  font-weight: 700;
}

.afp-registration__status_cell .afp-status-tag,
.afp-registration__section_container .afp-status-tag {
  background-color: white !important;
  border: 1px solid lightgray;
}

.afp-registration__section_container tr {
  border-bottom: 1px solid lightgray;
  vertical-align: text-top;
}

.afp-registration__section_container tr td:last-child {
  text-align: right;
  width: 12rem;
}

.afp-registration__section_lp_register {
  text-align: center;
}
