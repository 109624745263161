.ca-form-section {
  display: block !important;

  .section-header {
    margin-bottom: 0.25rem !important;
    border-bottom: 0 !important;
  }
}

.customer-account-view {
  & th {
    vertical-align: top;
  }
}
.contact-card {
  min-height: 7.5625rem; /* 121px */
  border-radius: 0.1875rem; /* 3px */
  border: solid #dfe1e2; /* 1px */
  background: linear-gradient(0deg, #eff6fb, #eff6fb);
}
