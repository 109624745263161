.repair-by-po-card {
  margin-bottom: 1.5rem;
  .text-total {
    font-size: 1.5rem;
  }
  .usa-tooltip__body {
    content: '';
    position: absolute;
    top: -70px;
    left: -20px;
    border-color: #fffbe8 transparent transparent transparent;
    transform: translateX(-50%);
  }
}

.repair-by-po-card {
  .afp-responsive-table {
    margin: 0rem !important;
  }

  .afp-responsive-table thead tr th {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .afp-responsive-table tbody tr th {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    padding-left: 1.25rem !important;
    padding-right: 0rem !important;
  }
  .afp-responsive-table tbody tr td {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    padding-left: 1.25rem !important;
    padding-right: 0rem !important;
  }
}
