.afp-registration__download_button {
  align-items: center;
  justify-content: flex-end;
}

.afp-registration__download_button svg {
  transform: translate(-0.2rem, 0.2rem);
}

.afp-registration__status_cell .afp-status-tag,
.afp-registration__section_container .afp-status-tag {
  background-color: white !important;
  border: 1px solid lightgray;
}

.afp-registration__section_container tr {
  border-bottom: 1px solid lightgray;
}

.afp-registration__section_container tr td:last-child {
  text-align: right;
}

.afp-registration__section_lp_register {
  text-align: center;
}
