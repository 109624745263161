.tag-pos {
  background-color: #9ce18b;
}
.tag-neg {
  background-color: #fee685;
}
.selected-row td {
  background-color: #e1f3f8;
}

.menu-dialog button,
.menu-dialog button:hover {
  color: #005ea2;
  text-decoration: none;
}
