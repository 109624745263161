.afp-vehicle-registration__input_validation {
  position: absolute;
  bottom: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.afp-vehicle-registration__input_validation .afp-spinner {
  margin-right: 0.5rem;
}

.afp-vehicle-registration__vin_input {
  max-width: none !important;
  width: calc(100% - 17rem);
}

.afp-registration__vin_bold {
  font-weight: 900;
  color: #005ea2;
}

.afp-registration__vin_strong {
  font-weight: 900;
  display: inline-block;
}

.afp-registration__vin-validation_form .modal {
  padding: 0;
}

.afp-registration__vin-validation_form .modal .title,
.afp-registration__vin-validation_form .modal .content,
.afp-registration__vin-validation_form .modal .actions {
  padding: 2rem;
}

.afp-registration__vin-validation_form .modal .title {
  border-bottom: 1px solid #c3c3c3;
}

.success-checkmark {
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #00a91c;
}

.check {
  display: inline-block;
  transform: rotate(45deg);
  height: 24px;
  width: 12px;
  border-bottom: 7px solid #fff;
  border-right: 7px solid #fff;
}

.disableBodyScroll {
  overflow: hidden;
}
