.prefix-icon-miles {
  position: absolute;
  bottom: 0.4rem;
  right: 0.75rem;
  color: #71767a;
  z-index: 1;
}

.miles-input {
  position: relative;
}

.miles-input .usa-input {
  text-align: left;
  padding-right: 3.5rem;
}

.color-red {
  background-color: red;
}
