.ccn-filter-wrapper {
  margin-top: 0.5rem;
  position: relative;
  display: inline-block;
}

.ccn-filter-wrapper input {
  padding: 0 24px;
  margin: 0;
}

.ccn-filter-wrapper .usa-form-group {
  margin: 0;
}

.ccn-filter-wrapper .filter-search-icon {
  position: absolute;
  left: 0.5rem;
  bottom: 0.7rem;
}

.ccn-filter-wrapper .filter-clear-button {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  cursor: pointer;
  background-color: transparent;
  border: 0;
}
