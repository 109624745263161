.zonal-side-nav .afp-sidenav .sidenav-item {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.zonal-side-nav .afp-sidenav .sidenav-item .zonal-sidenav-button {
  padding: 1rem;
}

.zonal-side-nav .afp-sidenav .sidenav-item .zonal-sidenav-button {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.zonal-side-nav .afp-sidenav .usa-current::after {
  width: 0;
}

.zonal-side-nav .afp-sidenav .sidenav-item .sidenav-item-body.usa-current {
  background-color: #eff6fb;
}
