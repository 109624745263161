.exempt-label-font label {
  font-size: 14px;
  font-weight: 500;
}
.exempt-label-font label::before {
  top: 10px;
}
.exempt-popover-wrapper {
  text-align: left;
}
.exempt-popover-wrapper-review {
  top: 5rem;
  left: 26rem;
}

.exempt-pop:hover .exempt-popover {
  display: block;
}

.exempt-message-popover {
  display: none;
}
.exempt-message-popover .afp-menu,
.exempt-message-popover .popover-arrow-content {
  background-color: #fffbe8;
}
.exempt-pop:hover .exempt-message-popover {
  display: block;
  position: absolute;
  top: 1.5rem;
  right: -0.5rem;
  z-index: 10;
}
