.modal {
  max-height: 80vh;
  overflow: auto;
  max-width: 75vw;
  width: 880px;
}
.exempt-check .usa-checkbox__label::before {
  /* padding-top: 1rem; */
}

.exempt-popover-container .exempt-popover {
  display: none;
  background-color: #fffbe8;
}
.exempt-popover-container:hover .afp-popover {
  display: block;
}
.field-validation-label {
  font-weight: bold;
}
.field-validation-label:not(:last-of-type)::after {
  content: ',';
  margin-right: 0.3rem;
}

/* ~~~ STYLES FROM OLD REG ~~~ */
.afp-vehicle-registration-wrapper label {
  font-weight: bold;
}

.afp-vehicle-next-btn span {
  margin-right: 10px;
}

.afp-vehicle-prev-btn span {
  margin-left: 10px;
}

.afp-arrow-icon-left {
  transform: rotate(180deg);
}

.afp-registration__poc_form input {
  max-width: unset;
}

.afp-vehicle-registration__section_text {
  margin: 0 !important;
}

.afp-vehicle-registration .usa-step-indicator__segment {
  max-width: unset !important;
}

.afp-vehicle-registration__edit_button {
  padding: 0.5rem 1rem;
  margin-left: 1rem;
}

.afp-vehicle-registration__vehicle_status {
  padding-left: 1rem;
  display: table-cell;
  vertical-align: middle;
}

.afp-vehicle-registration__step .usa-form-group--error {
  margin-left: unset !important;
}

.afp-vehicle-registration__anchor {
  text-decoration: underline;
}

.afp-vehicle-registration__links_container .display-inline:last-child {
  border-left: 1px solid lightgray;
}

.afp-vehicle-registration__download_registration {
  font-size: 0.9rem !important;
  padding: 0.5rem 1rem;
}

.afp-vehicle-registration__registration_info {
  background: #f3f8fb;
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
}

.afp-vehicle-registration__pdf_spinner .afp-spinner {
  display: unset !important;
}

.afp-vehicle-registration__pdf_spinner_message {
  display: inline-block;
  transform: translate(1rem, -0.2rem);
  font-style: italic;
}

.menu-dialog button,
.menu-dialog button:hover {
  color: inherit;
  text-decoration: none;
}

.afp-registration-no-border {
  border: none;
}

.afp-vehicle-registration-wrapper .afp-details-table tr {
  border-bottom: 1px solid rgb(234, 235, 236);
}

.afp-vehicle-registration__cannot_register .modalClose {
  display: none;
}

.afp-vehicle-registration__cannot_register .modal {
  padding: 0;
}

.afp-vehicle-registration__cannot_register .modal .title,
.afp-vehicle-registration__cannot_register .modal .content,
.afp-vehicle-registration__cannot_register .modal .actions {
  padding: 2rem;
}

.afp-vehicle-registration__cannot_register .modal .content {
  padding-top: 0;
}

#afp_cannot-register a {
  color: white !important;
}

.exempt-label-font label {
  font-size: 14px;
  font-weight: 500;
}
.exempt-label-font label::before {
  top: 10px;
}
.exempt-popover-wrapper {
  top: 1rem;
  left: 14rem;
  z-index: 10;
}
.exempt-popover-wrapper-review {
  top: 5rem;
  left: 26rem;
}

/* POC LOOKUP - OLD REG STYLES */
.afp-vehicle-registration__poc_info_read label {
  font-weight: 900;
  margin: 0;
}
.afp-vehicle-registration__poc_lookup_input {
  flex-grow: 1;
}
.input_success {
  border: 4px solid #00a91c;
}

.afp-vehicle-registration__poc_info_read {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 30px 0px;
  background: #f6f6f6;
  border-radius: 0px 0px 10px 10px;
  margin-top: 10px;
}

.afp-vehicle-registration__poc_lookup {
  display: flex;
  align-items: flex-end;
}
input[name='phone']::-webkit-inner-spin-button,
input[name='phone']::-webkit-outer-spin-button,
input[name='ext']::-webkit-inner-spin-button,
input[name='ext']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.afp-registration__poc_form .modal {
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
  border-radius: 2px;
  max-width: 880px;
  min-width: 400px;
  width: 880px;
  max-height: 100vh;
  overflow: auto;
}

.w-225 {
  width: 225px;
}
