.exempt-pop {
  display: flex;
}
.exempt-pop svg {
  fill: #005ea2;
}
.exempt-pop .exempt-popover {
  margin-top: 0.2rem;
  margin-left: 0.5rem;
}
.exempt-pop .afp-popover {
  width: 30rem;
}
.exempt-popover {
  display: none;
}
.exempt-popover .afp-menu,
.exempt-popover .popover-arrow-content {
  background-color: #fffbe8;
}
.exempt-pop:hover .exempt-popover {
  display: block;
}
