.vms-summary-card {
  border: 1px solid #d9e8f6;
  border-radius: 3px;
  background-color: #eaf2f8;
  padding: 1rem;
  width: 13rem;
  height: 6.75rem;
  margin-right: 0.5rem;
}

.vms-summary-card-w-footnote {
  width: 14rem;
  height: 8.25rem;
}
