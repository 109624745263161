.prefix-icon-odometer {
  position: absolute;
  bottom: 0.375rem;
  right: 1.75rem;
  color: #71767a;
  z-index: 1;
}

.odometer-input .usa-input {
  text-align: right;
  padding-right: 3.5rem;
}
