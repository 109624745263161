.repair-order-total-table tr > th {
  padding: 1rem 1.5rem;
}
.repair-order-total-table tr > td {
  padding: 1rem 1.5rem;
}
.repair-order-total-table tr td:last-child {
  text-align: right;
}

.repair-order-total-table tr:last-child {
  font-weight: 700;
  background-color: #eff6fb;
  border-bottom: 2px solid;
  border-color: black !important;
}
