body {
  margin: 0;
  font-family: 'Public Sans Web', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.temporary-prop {
  background: red;
  color: white;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.5rem;
  margin-right: 0.5rem;
}

.afp-responsive-table tbody tr td > button.pm-invoice {    
  padding-top: 0.625rem !important;   
  padding-bottom: 0.625rem !important; 
}
