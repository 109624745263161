/* This needs to be here to match Figma. The BM purchase rate Date Picker has css too */
.changeFontWeight {
  font-weight: 600;
  margin: 10px 0px;
}

.datePicker {
  width: 50%;
}

.date-picker-error input {
  border: 0.19rem solid #b50909;
  width: 50%;
}

.error-border-color {
  border-color: #b50909;
}

.labelError {
  color: #b50909;
  font-weight: 600;
}
