#main-content {
  padding-bottom: 32px;
}

#main-content .white-anchor-text {
  color: white !important;
  white-space: nowrap;
}

.afp-green-5v {
  background: #ddf9c7;
}

.afp-blue-5-color {
  color: #eff6fb;
}

.afp-blue-5-background {
  background: #eff6fb;
}

.afp-text-overflow-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-800 {
  font-weight: 800;
}

.detail-value {
  text-align: right;
}

/* to be moved to comp. library*/
.loading_backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
}

/* temporary fix */
.afp-address-confirmation-modal .usa-radio {
  background: none;
}

/* temporary fix */
.text-bold label {
  font-weight: bold;
}

/* temporary fix - remove when Table is retro-fitted with AFPTable */
.usa-table thead th[aria-sort] {
  background-color: white;
}

.prefix-icon-dollar {
  position: absolute;
  bottom: 0.6rem;
  left: 0.3rem;
  font-size: 1.25em;
  color: #71767a;
}

.dollar-input {
  position: relative;
}

.dollar-input input {
  text-align: right;
}

.unset-max-width input {
  max-width: unset;
}
.unset-max-width textarea {
  max-width: unset;
}

.usa-tooltip__body {
  background-color: #fffbe8;
  color: #1b1b1b;
}

.usa-tooltip__body--right:after {
  border-right: 5px solid #fffbe8;
}

.minusColor {
  color: #e41d3d;
}

.overflow-wrap-text {
  overflow-wrap: anywhere;
}

.editable-cell input,
.editable-cell .usa-input-group {
  margin: 0;
}

.afp-responsive-table .usa-tooltip__body {
  background-color: #1b1b1b;
  color: #f0f0f0;
}

.filters__accordion-container .usa-form-group {
  margin-top: 0;
}
