.vehicle-overview-container > .vehicle-overview-row {
  width: 100%;
  display: flex;
  flex-direction: flex-row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.vehicle-overview-container > .vehicle-overview-row > .overview-card-lg,
.vehicle-overview-container > .vehicle-overview-row > .overview-card-sm {
  flex: 1 1 0;
  border: 1px solid #d9e8f6;
  border-radius: 3px;
  background-color: #eaf2f8;
  padding: 1rem;
  max-width: 50%;
}
.vehicle-overview-container > .vehicle-overview-row > .overview-card-lg {
  min-width: 20rem;
  min-height: 10rem;
}
.vehicle-overview-container > .vehicle-overview-row > .overview-card-sm {
  min-width: 10rem;
  min-height: 7.5rem;
}
.vehicle-overview-container > .vehicle-overview-row > .filler-card-sm {
  flex: 1 1 0;
  min-width: 10rem;
  padding: 0 1rem;
  height: 0;
}

.vehicle-overview-details-table .afp-status-tag {
  background-color: white;
}

.vehicle-overview-details-table tr {
  border-bottom: 1px solid lightgray;
  vertical-align: text-top;
}

.vehicle-overview-details-table tr td:last-child {
  text-align: right;
  width: 12rem;
}

.vehicle-location tr td:last-child {
  text-align: right;
  width: 18rem;
}
