.exempt-label-font label {
  font-size: 14px;
  font-weight: 500;
}
.exempt-label-font label::before {
  top: 10px;
}
.exempt-popover-wrapper {
  display: inline-block;
  padding-left: 0.2rem;
  text-indent: 0rem;
}
.exempt-popover-wrapper-review {
  top: 5rem;
  left: 26rem;
}

.exempt-value-row {
  display: flex;
  margin-left: -20px;
}

.exempt-value {
  margin-right: 20px;
}

.exempt-popover-value-icon {
  margin-left: 60px;
  margin-top: -5px;
}

.exempt-pop .exempt-checkbox-popover {
  margin-top: 0.2rem;
}
.exempt-pop .afp-popover {
  width: 30rem;
}
.exempt-checkbox-popover {
  display: none;
}
.exempt-checkbox-popover .afp-menu,
.exempt-checkbox-popover .popover-arrow-content {
  background-color: #fffbe8;
}
.exempt-pop:hover .exempt-checkbox-popover {
  display: block;
  position: absolute;
  top: 4rem;
  left: 0;
  z-index: 10;
}
