/* TODO: After the tooltip implementation in component library and retrofit, this css needs to be removed */
.remedy-tooltip .usa-tooltip__body {
  position: absolute;
  top: -52px;
  left: -147px;
  width: 300px;
  white-space: normal;
}

.remedy-tooltip .usa-tooltip__body--top:after {
  border-top: 5px solid #fffbe8;
}

.recall-comments .activity-panel-dot {
  padding-bottom: 5px;
}
