.repairs-subrow-details-table tr th,
.repairs-subrow-details-table tr td {
  background-color: transparent !important;
  border-top: none;
  border-bottom: 1px solid #d9e8f6 !important;
}

.repairs-subrow-details-table tr th {
  padding: 0.75rem 1rem 0.5rem 0 !important;
  font-weight: bold;
}

.repairs-subrow-details-table tr td {
  padding: 0.75rem 0 0.5rem 1rem !important;
  text-align: right;
}

.repairs-subrow-details-table tr {
  border-bottom: 1px solid blue;
  vertical-align: text-top;
}
